.post-comments {
  .send-comment-btn {
    float: right;
    color: white;
    border: 2px solid white!important;
    background-color: #05f;
  }
  .send-comment-btn:hover {
    color: #05f!important;
    border: 2px solid #05f!important;
    background-color: white;
  }
  .send-comment-btn.disabled {
    pointer-events: none;
  }
  .comment-content {
    font-size: 15px;
    color: rgb(81, 81, 132);
  }
  .post-comment-card {
    margin-bottom: 15px;
  }
  .delete-comment-link {
    color: red;
    cursor: pointer;
  }
  .edit-comment-link::after,
  .reply-comment-link::after,
  .delete-comment-link::after {
    content: " • ";
    color: #adb5bd;
  }
  .edit-comment-link,
  .reply-comment-link {
    cursor: pointer;
    color: black;
  }
  .comment-avatar {
    max-width: 30px;
    max-height: 30px;
  }
  .reply-title {
    cursor: pointer;
  }
}