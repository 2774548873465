.user-name {
  overflow: hidden;
  white-space: nowrap;
}

.chat-user-actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
}

.chat-avatar-card {
  border: none;
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  margin: 8px;
  .chat-avatar-img {
    height: 56px;
    flex-direction: column;
  }
  .chat-avatar-info {
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    .chat-avatar-info-name {
      font-size: 15px;
      color: rgb(5, 5, 5);
      line-height: 16px;
      font-weight: 400;
    }
    .chat-avatar-info-history {
      font-size: 13px;
      color: rgb(101, 103, 107);
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.chat-avatar-card.chat-avatar-unread {
  .chat-avatar-info {
    .chat-avatar-info-history,
    .chat-avatar-info-name {
      font-weight: 600;
    }
  }
}

.chat-avatar-selected,
.chat-avatar-card:hover {
  background-color: #eee;
}

.chat-avatar-unread span {
  font-weight: bold;
}