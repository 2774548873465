.postView {
  .card-body {
    position: relative;
  }
  .card-body-header {
    width: 100%;
    .avatar {
      flex-basis: 11%;
      text-align: left;
    }
    h4 {
      flex-basis: 69%;
      text-align: left;
    }
    .pin {
      flex-grow: 1;
      text-align: right;
    }
    .context-menu {
      flex-grow: 1;
      text-align: right;
    }
  }
  .section-tag {
    padding: 5px 15px;
    border-radius: 10px;
    color: #fff;
  }
  .highlight-image {
    background: #fbfcfe;
    border: 1px solid #eef;
    img {
      max-height: 500px;
      margin: auto;
      display: flex;
      max-width: 100%;
    }
  }
  .dropdown-menu {
    position: absolute;
  }
}
.postView.card {
  border-radius: 8px!important;
}

.emojis {
  .emoji {
    opacity: 0.5;
  }
  .emoji:not(:last-child) {
    margin-right: 1rem;
  }
  .emoji:hover {
    opacity: 1;
  }
  .emoji.active {
    opacity: 1;
  }
}

.post-section-button {
  display: none;
}
.postView .card-body-header .avatar {
  flex-basis: 20%;
}

@media (min-width: 500px) {
  .post-section-button {
    display: flex;
  }
  .postView .card-body-header .avatar {
    flex-basis: 11%;
  }
}