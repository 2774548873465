.post-detail {
  .post-content {
    li {
      list-style: auto;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    video {
      max-width: 100%;
    }
  }
  .post-content-bottom {
    width: 100%;
    .emoji-section {
      flex-basis: 50%;
      width: 150px;
      align-self: flex-end;
    }
    .pcb-section-name {
      flex-basis: 50%;
      width: 150px;
      margin-left: auto;
      text-align: right;
      justify-content: right;
      vertical-align: middle;
    }
  }
  .subtitle-data {
    justify-content: space-between;
    margin-bottom: 40px;
    .author-info {
      display: flex;
    }
  }
  .section-tag {
    padding: 5px 15px;
    border-radius: 10px;
    color: #fff;
    margin-top: 1rem;
  }
  .post-highlight-img {
    background: #fbfcfe;
    border: 1px solid #eef;
    margin-bottom: 1rem;
    img {
      max-height: 400px;
      margin: auto;
      display: flex;
      max-width: 100%;
    }
  }
}

.back-button {
  cursor: pointer;
}