@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.chat-user-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  border-right: 1px solid #ddd;

  .chat-user-list-search {
    display: flex;
    justify-content: center;
    margin: 0 16px;
  }

  .chat-user-list-search > input {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 100%;
    line-height: 25px;
  }

  .chat-user-list-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .chat-user-list-heading > h1 {
    margin-left: 16px;
    font-weight: 600;
  }

  .chat-user-list-heading > i {
    background-color: rgb(228, 230, 235);
    padding: 10px;
    border-radius: 25px;
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
  .chat-user-list-heading > i:hover {
    background-color: rgb(204, 204, 206);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-user-list::-webkit-scrollbar {
  display: none;
}
