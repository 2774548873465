.events-page-container {
  .card-image {
    max-height: 180px;
    text-align: center;
    background: #fbfcfe;
    border: 1px solid #eef;
    > img {
      height: 180px;
    }
  }
  .card-date {
    max-height: 72px;
    display: flex;
    align-items: center;
  }
  .card-body {
    min-height: 175px;
  }
  .event-finished-label {
    color: black;
    font-size: 13px;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}