.social-icon {
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &.feather-facebook {
    border: 1px solid #4267B2;
    color: #4267B2;

    &:hover {
      color: white;
      background-color: #4267B2;
    }
  }

  &.feather-instagram {
    border: 1px solid #F56040;
    color: #F56040;

    &:hover {
      color: white;
      background-color: #F56040;
    }
  }

  &.feather-twitter {
    border: 1px solid #1DA1F2;
    color: #1DA1F2;

    &:hover {
      color: white;
      background-color: #1DA1F2;
    }
  }

  &.feather-linkedin {
    border: 1px solid #0A66C2;
    color: #0A66C2;

    &:hover {
      color: white;
      background-color: #0A66C2;
    }
  }

  &.feather-chrome {
    border: 1px solid #657786;
    color: #657786;

    &:hover {
      color: white;
      background-color: #657786;
    }
  }
}