.post-btn {
  margin: auto 0 auto auto;
  padding: 5px 15px;
  background-color: transparent;
}

.sub-post-elements {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
}

.sub-post-elements > .featured-image-input {
  flex-basis: 80%;
  width: 80%;
}

.sub-post-elements > .featured-image-input > label {
  font-size: 13px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.sub-post-elements > .submit-button {
  flex-basis: 20%;
}

