.search-input:focus {
  border: 1px solid black!important;
}

.notification-tag {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  border-radius: 15px;
  background-color: red;
}