.chat-page-content {
  display: flex;
  align-items: stretch;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
}

.scroll-div {
  max-height: 100vh;
  overflow: auto;
}