.event-detail {
  .subtitle-data {
    justify-content: space-between;
    margin-bottom: 50px;

    .author-info {
      display: flex;
    }
  }
  .section-tag {
    padding: 5px 15px;
    border-radius: 10px;
    color: #fff;
    margin-top: 1rem;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  video {
    max-width: 100%;
  }
  .event-highlight-img {
    background: #fbfcfe;
    border: 1px solid #eef;
    margin-bottom: 1rem;
    img {
      max-height: 400px;
      margin: auto;
      display: flex;
      max-width: 100%;
    }
  }
}