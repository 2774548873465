.no-chat-selected {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-form .form-group:after {
  // width: calc(100% - 70px);
  pointer-events: none;
}

.chat-form input {
  color: black;
}

.chat-bottom {
  background-color: white;
  position: absolute;
}

.chat-body {
  margin-top: 68px!important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .messages-content {
    // min-height: 100vh;
  }
}

.chat-body::-webkit-scrollbar {
  display: none;
}

.chat-body .messages-content .message-item .message-wrap {
  box-shadow: none;
  line-height: 20px;
  max-width: 500px;

  p {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
  }
}

.chat-body .messages-content .message-item .message-user {
  margin-bottom: 0;
}

.chat-body .messages-content .message-item {
  margin-bottom: 30px;
}

.chat-body .messages-content .infinite-scroll-component__outerdiv {
  width: 100%;
}

@media (min-width: 993px) {
  .chat-user-list {
    .chat-avatar-card {
      width: 330px;
    }
  }
}

@media (max-width: 991px) {
  .chat-bottom {
    bottom: 66px;
  }
  .chat-user-list .chat-user-list-search,
  .chat-user-list .chat-avatar-card .chat-avatar-info {
    display: none;
  }
  .chat-user-list {
    padding-bottom: 56px;
    .chat-avatar-card {
      padding: 2px;
      margin: 2px;

      figure {
        text-align: center;
      }
    }
  }
  .chat-body .messages-content {
    padding-bottom: 126px!important;
  }
}

#message {
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  padding-left: 15px;
  padding-top: 5px;
}