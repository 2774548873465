.shadow-xss {
  box-shadow: rgba(140, 140, 140, 0.2) 0 0 0 1px !important;
  border-radius: 8px !important;
}

.only-shadow-xss {
  box-shadow: rgba(140, 140, 140, 0.2) 0 0 0 1px !important;
}

.nav-header {
  height: 60px;

  .nav-top a {
    width: 200px;
  }
}

.right-navigation {
  display: none;
}

.main-content {
  padding-bottom: 56px;
}

@media (min-width: 992px) {
  .main-content {
    padding-top: 55px;
    padding-bottom: 0;
  }
  .navigation {
    padding-top: calc(55px + 6px);

    .nav-wrap {
      box-shadow: rgba(140, 140, 140, 0.2) 0 0 0 1px !important;
      border-radius: 8px !important;
    }
  }
  .right-navigation {
    display: block;
  }
}

.emoji-wrap {
  bottom: 45px;
  left: -5px;
}

.em.emoji-like {
  background-image: url("../public/assets/images/emoji/emoji_like.png")
}

.em.emoji-heart {
  background-image: url("../public/assets/images/emoji/emoji_heart.png")
}

.em.emoji-wow {
  background-image: url("../public/assets/images/emoji/emoji_wow.png")
}

.em.emoji-ahah {
  background-image: url("../public/assets/images/emoji/emoji_ahah.png")
}

.em.emoji-sigh {
  background-image: url("../public/assets/images/emoji/emoji_sigh.png")
}

.navigation.scroll-bar::-webkit-scrollbar {
  display: none;
}

.nav-header .nav-top a,
.nav-header .nav-top a .logo-text {
  line-height: 60px;
}

.header-search {
  height: 100%;
  align-items: center;
  display: flex;
}

.navigation .nav-content ul li:not(.logo) a {
  height: 50px;
}

/*height: 100%;
    align-items: center;
    display: flex;
    background-color: white;*/