.chat-top {
  position: fixed;
  left: 50%;
  top: 60px;
  width: 100%;
  transform: translateX(-50%);
  z-index: 10;
  margin-top: 1px;

  display: flex;
  align-items: stretch;

  .chat-top-recipient-details {
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #ddd;;
    z-index: 10;
    background-color: white;
    .chat-avatar-info-history {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    a {
      line-height: 1;
    }
  }

  .chat-top-recipient-details.chat-avatar-card {
    margin: 0;
    cursor: default;
  }

  .chat-top-dummy {
    width: 347px;
    min-width: 347px;
    max-width: 347px;
  }
}

@media (max-width: 991px) {
  .chat-top .chat-top-dummy {
    width: 84px;
    min-width: 84px;
    max-width: 84px;
  }
}