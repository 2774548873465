.public-profile-view-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  .feather-eye {
    border: 1px solid rgb(81, 81, 132);
    padding: 10px;
    border-radius: 20px;
    color: rgb(81, 81, 132);
  }
  .feather-eye:hover {
    background-color: rgb(81, 81, 132);
    color: white;
    border-color: white;
  }
}

.save-account-btn {
  float: right;
  color: white;
  border: 2px solid white !important;
  background-color: #05f;
}

.save-account-btn:hover {
  color: #05f !important;
  border: 2px solid #05f !important;
  background-color: white !important;
}

.save-account-btn.disabled {
  pointer-events: none;
}

.saving-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
}

.saving-overlay > img {
  width: 100px;
  height: 100px;
}

.saving-overlay > h3,
.saving-overlay > h1 {
  color: white;
}